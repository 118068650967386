// Generates classes to assign animations
@mixin animation-classes($animation-name) {

  $directions-list: in out;
  $positions-list:  top bottom left right;
  
  @each $direction in $directions-list {
    @each $position in $positions-list {

      $class-name: #{$animation-name}--#{$direction}-#{$position};

      .#{$class-name} {
        .direction-reveal__overlay {
          animation-name: #{$class-name};
        }
      }

    }
  }

}



// Swing animation

@include animation-classes('swing');

// Default visible state after animation - used as start or end points for the keyframe animations
.direction-reveal [class*='swing--'] {
  .direction-reveal__overlay {
    transform: rotate3d(0, 0, 0, 0);
    animation-timing-function: $anim-easing-easeOutCubic;
  }
}

.swing--in-top, .swing--out-top {
  perspective-origin: center top;
  
  .direction-reveal__overlay {
    transform-origin: center top;
  }
}

.swing--in-bottom, .swing--out-bottom {
  perspective-origin: center bottom;
  
  .direction-reveal__overlay {
    transform-origin: center bottom;
  }
}

.swing--in-left, .swing--out-left {
  perspective-origin: left center;
  
  .direction-reveal__overlay {
    transform-origin: left center;
  }
}

.swing--in-right, .swing--out-right {
  perspective-origin: right center;
  
  .direction-reveal__overlay {
    transform-origin: right center;
  }
}


@keyframes swing--in-top {
  0% {
    transform: rotate3d(-1, 0, 0, 90deg);
  }
}

@keyframes swing--out-top {
  100% {
    transform: rotate3d(-1, 0, 0, 90deg);
  }
}

@keyframes swing--in-bottom {
  0% {
    transform: rotate3d(1, 0, 0, 90deg);
  }
}

@keyframes swing--out-bottom {
  100% {
    transform: rotate3d(1, 0, 0, 90deg);
  }
}

@keyframes swing--in-left {
  0% {
    transform: rotate3d(0, 1, 0, 90deg);
  }
}

@keyframes swing--out-left {
  100% {
    transform: rotate3d(0, 1, 0, 90deg);
  }
}

@keyframes swing--in-right {
  0% {
    transform: rotate3d(0, -1, 0, 90deg);
  }
}

@keyframes swing--out-right {
  100% {
    transform: rotate3d(0, -1, 0, 90deg);
  }
}



// Slide animation

@include animation-classes('slide');

.direction-reveal [class*='slide--'] {
  .direction-reveal__overlay {
    transform: translate3d(0, 0, 0);
    animation-timing-function: $anim-easing-easeOutQuad;
  }
}

@keyframes slide--in-top {
  0% {
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slide--out-top {
  100% {
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slide--in-bottom {
  0% {
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slide--out-bottom {
  100% {
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slide--in-left {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slide--out-left {
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}


@keyframes slide--in-right {
  0% {
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slide--out-right {
  100% {
    transform: translate3d(100%, 0, 0);
  }
}



// Rotate animation

@include animation-classes('rotate');

.direction-reveal [class*='rotate--'] {
  .direction-reveal__overlay {
    transform: rotate(0);
    animation-timing-function: $anim-easing-easeOutQuad;
  }
}

.rotate--in-top, .rotate--out-top {  
  .direction-reveal__overlay {
    transform-origin: left top;
  }
}

.rotate--in-bottom, .rotate--out-bottom {  
  .direction-reveal__overlay {
    transform-origin: left bottom;
  }
}

.rotate--in-left, .rotate--out-left {
  .direction-reveal__overlay {
    transform-origin: left top;
  }
}

.rotate--in-right, .rotate--out-right {
  .direction-reveal__overlay {
    transform-origin: right top;
  }
}

@keyframes rotate--in-top {
  0% {
    transform: rotate(-90deg);
  }
}

@keyframes rotate--out-top {
  100% {
    transform: rotate(-90deg);
  }
}

@keyframes rotate--in-bottom {
  0% {
    transform: rotate(90deg);
  }
}

@keyframes rotate--out-bottom {
  100% {
    transform: rotate(90deg);
  }
}

@keyframes rotate--in-left {
  0% {
    transform: rotate(90deg);
  }
}

@keyframes rotate--out-left {
  100% {
    transform: rotate(90deg);
  }
}

@keyframes rotate--in-right {
  0% {
    transform: rotate(-90deg);
  }
}

@keyframes rotate--out-right {
  100% {
    transform: rotate(-90deg);
  }
}
