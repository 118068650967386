.swing--in-top .direction-reveal__overlay {
  animation-name: swing--in-top;
}

.swing--in-bottom .direction-reveal__overlay {
  animation-name: swing--in-bottom;
}

.swing--in-left .direction-reveal__overlay {
  animation-name: swing--in-left;
}

.swing--in-right .direction-reveal__overlay {
  animation-name: swing--in-right;
}

.swing--out-top .direction-reveal__overlay {
  animation-name: swing--out-top;
}

.swing--out-bottom .direction-reveal__overlay {
  animation-name: swing--out-bottom;
}

.swing--out-left .direction-reveal__overlay {
  animation-name: swing--out-left;
}

.swing--out-right .direction-reveal__overlay {
  animation-name: swing--out-right;
}

.direction-reveal [class*='swing--'] .direction-reveal__overlay {
  transform: rotate3d(0, 0, 0, 0);
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.swing--in-top, .swing--out-top {
  perspective-origin: center top;
}

.swing--in-top .direction-reveal__overlay, .swing--out-top .direction-reveal__overlay {
  transform-origin: center top;
}

.swing--in-bottom, .swing--out-bottom {
  perspective-origin: center bottom;
}

.swing--in-bottom .direction-reveal__overlay, .swing--out-bottom .direction-reveal__overlay {
  transform-origin: center bottom;
}

.swing--in-left, .swing--out-left {
  perspective-origin: left center;
}

.swing--in-left .direction-reveal__overlay, .swing--out-left .direction-reveal__overlay {
  transform-origin: left center;
}

.swing--in-right, .swing--out-right {
  perspective-origin: right center;
}

.swing--in-right .direction-reveal__overlay, .swing--out-right .direction-reveal__overlay {
  transform-origin: right center;
}

@keyframes swing--in-top {
  0% {
    transform: rotate3d(-1, 0, 0, 90deg);
  }
}

@keyframes swing--out-top {
  100% {
    transform: rotate3d(-1, 0, 0, 90deg);
  }
}

@keyframes swing--in-bottom {
  0% {
    transform: rotate3d(1, 0, 0, 90deg);
  }
}

@keyframes swing--out-bottom {
  100% {
    transform: rotate3d(1, 0, 0, 90deg);
  }
}

@keyframes swing--in-left {
  0% {
    transform: rotate3d(0, 1, 0, 90deg);
  }
}

@keyframes swing--out-left {
  100% {
    transform: rotate3d(0, 1, 0, 90deg);
  }
}

@keyframes swing--in-right {
  0% {
    transform: rotate3d(0, -1, 0, 90deg);
  }
}

@keyframes swing--out-right {
  100% {
    transform: rotate3d(0, -1, 0, 90deg);
  }
}

.slide--in-top .direction-reveal__overlay {
  animation-name: slide--in-top;
}

.slide--in-bottom .direction-reveal__overlay {
  animation-name: slide--in-bottom;
}

.slide--in-left .direction-reveal__overlay {
  animation-name: slide--in-left;
}

.slide--in-right .direction-reveal__overlay {
  animation-name: slide--in-right;
}

.slide--out-top .direction-reveal__overlay {
  animation-name: slide--out-top;
}

.slide--out-bottom .direction-reveal__overlay {
  animation-name: slide--out-bottom;
}

.slide--out-left .direction-reveal__overlay {
  animation-name: slide--out-left;
}

.slide--out-right .direction-reveal__overlay {
  animation-name: slide--out-right;
}

.direction-reveal [class*='slide--'] .direction-reveal__overlay {
  transform: translate3d(0, 0, 0);
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@keyframes slide--in-top {
  0% {
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slide--out-top {
  100% {
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slide--in-bottom {
  0% {
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slide--out-bottom {
  100% {
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slide--in-left {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slide--out-left {
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slide--in-right {
  0% {
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slide--out-right {
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.rotate--in-top .direction-reveal__overlay {
  animation-name: rotate--in-top;
}

.rotate--in-bottom .direction-reveal__overlay {
  animation-name: rotate--in-bottom;
}

.rotate--in-left .direction-reveal__overlay {
  animation-name: rotate--in-left;
}

.rotate--in-right .direction-reveal__overlay {
  animation-name: rotate--in-right;
}

.rotate--out-top .direction-reveal__overlay {
  animation-name: rotate--out-top;
}

.rotate--out-bottom .direction-reveal__overlay {
  animation-name: rotate--out-bottom;
}

.rotate--out-left .direction-reveal__overlay {
  animation-name: rotate--out-left;
}

.rotate--out-right .direction-reveal__overlay {
  animation-name: rotate--out-right;
}

.direction-reveal [class*='rotate--'] .direction-reveal__overlay {
  transform: rotate(0);
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.rotate--in-top .direction-reveal__overlay, .rotate--out-top .direction-reveal__overlay {
  transform-origin: left top;
}

.rotate--in-bottom .direction-reveal__overlay, .rotate--out-bottom .direction-reveal__overlay {
  transform-origin: left bottom;
}

.rotate--in-left .direction-reveal__overlay, .rotate--out-left .direction-reveal__overlay {
  transform-origin: left top;
}

.rotate--in-right .direction-reveal__overlay, .rotate--out-right .direction-reveal__overlay {
  transform-origin: right top;
}

@keyframes rotate--in-top {
  0% {
    transform: rotate(-90deg);
  }
}

@keyframes rotate--out-top {
  100% {
    transform: rotate(-90deg);
  }
}

@keyframes rotate--in-bottom {
  0% {
    transform: rotate(90deg);
  }
}

@keyframes rotate--out-bottom {
  100% {
    transform: rotate(90deg);
  }
}

@keyframes rotate--in-left {
  0% {
    transform: rotate(90deg);
  }
}

@keyframes rotate--out-left {
  100% {
    transform: rotate(90deg);
  }
}

@keyframes rotate--in-right {
  0% {
    transform: rotate(-90deg);
  }
}

@keyframes rotate--out-right {
  100% {
    transform: rotate(-90deg);
  }
}

.direction-reveal__card {
  display: inline-block;
  position: relative;
  overflow: hidden;
  perspective: 400px;
}

.direction-reveal__overlay {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, -100%, 0);
  width: 100%;
  height: 100%;
  padding: 15px;
  color: #fff;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-fill-mode: forwards;
}

.direction-reveal__title {
  margin-top: 0;
}

.direction-reveal__text {
  margin-bottom: 0;
}

.direction-reveal--3-grid-flexbox {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.direction-reveal--3-grid-flexbox .direction-reveal__card {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  border: 10px solid transparent;
}

.direction-reveal--3-grid-cssgrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-right: -10px;
  margin-left: -10px;
}

.direction-reveal--3-grid-cssgrid .direction-reveal__card {
  border: 10px solid transparent;
}

.direction-reveal--grid-bootstrap .direction-reveal__card {
  margin-top: 15px;
  margin-bottom: 15px;
}
